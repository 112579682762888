import {
  ChevronRight,
  Code,
  HelpOutline,
  School,
} from '@mapped/rivet/dist/mui/icons'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { truncate } from 'lodash'
import Link from 'next/link'
import { NextRouter, useRouter } from 'next/router'
import { FunctionComponent, useContext, useState } from 'react'
import { getSessionAuthToken, getSessionRefreshToken } from '../../auth/auth0'
import { UserContext } from '../../contexts/user'
import { Services } from '../../services'
import { EFeature } from '../../types/general'
import { UpgradeBanner } from '../billing/upgradeBanner'
import { Feature } from '../feature'
import { SandboxToggle } from '../sandbox/sandboxToggle'
import { Restrict } from '../user/restrict'
import { UserMenu } from './header-menus/user/index'

export const Header: FunctionComponent = () => {
  const router = useRouter()
  const { organization } = useContext(UserContext)

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)

  return (
    <>
      <Container>
        <Nav>
          <div style={{ paddingLeft: 20, width: '100%' }}>
            <Link href="/explore">
              <img
                data-testid="header-logo"
                src="/img/mapped-logo-wide.svg"
                alt="Mapped"
                style={{ height: 40, width: 133 }}
              />
            </Link>

            <LinkContainer>
              <Link href="/explore">
                <NavLink isActive={isPathActive('/explore', router)}>
                  Explore
                </NavLink>
              </Link>

              <Link href="/connectors">
                <NavLink isActive={isPathActive('/connectors', router)}>
                  Connectors
                </NavLink>
              </Link>

              <Restrict sandbox={false}>
                <Link href="/gateways">
                  <NavLink isActive={isPathActive('/gateways', router)}>
                    Gateways
                  </NavLink>
                </Link>
              </Restrict>

              <div style={{ flex: 1 }} />

              <Feature name={EFeature.ANAXI_BUTTON}>
                <NavCustomButtonWrapper>
                  <NavCustomButton
                    isActive={false}
                    onClick={() =>
                      redirectWithSession(Services.anaxi.url + '/api/redirect')
                    }
                  >
                    <School style={{ marginRight: 10 }} />
                    <span>Expert Center</span>
                  </NavCustomButton>
                </NavCustomButtonWrapper>
              </Feature>

              <SandboxToggle />

              <NavCustomButtonWrapper>
                <NavCustomButton
                  isActive={false}
                  onClick={() =>
                    redirectWithSession(
                      Services.devportal.url + '/api/redirect'
                    )
                  }
                >
                  <Code style={{ marginRight: 10 }} />
                  <span>Developers</span>
                </NavCustomButton>
              </NavCustomButtonWrapper>
            </LinkContainer>
          </div>

          <div>
            <NavButton
              data-testid="open-support"
              onClick={() =>
                window.open('https://support.mapped.com/', '_blank')
              }
            >
              <HelpOutline />
            </NavButton>

            <div className="relative">
              <NavButton
                data-testid="user-menu-button"
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                isActive={isUserMenuOpen}
              >
                <strong>
                  {truncate(organization?.name || '', { length: 30 })}
                </strong>

                {isUserMenuOpen ? (
                  <ChevronRight style={{ transform: 'rotate(-90deg)' }} />
                ) : (
                  <ChevronRight
                    color="secondary"
                    style={{ transform: 'rotate(90deg)' }}
                  />
                )}
              </NavButton>

              {isUserMenuOpen && (
                <UserMenu onClose={() => setIsUserMenuOpen(false)} />
              )}
            </div>
          </div>
        </Nav>
      </Container>

      <UpgradeBanner />
    </>
  )
}

export const TheVoid: FunctionComponent<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <div
      data-testid="the-void"
      onClick={onClick}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
      }}
    />
  )
}

function isPathActive(path: string, router: NextRouter) {
  return router.pathname === path || router.pathname.includes(path)
}

function redirectWithSession(url: string) {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = url
  form.target = '_blank'
  form.style.display = 'none'

  const tokenInput = document.createElement('input')
  tokenInput.type = 'hidden'
  tokenInput.name = 'authToken'
  tokenInput.value = getSessionAuthToken() || ''
  form.appendChild(tokenInput)

  const refreshTokenInput = document.createElement('input')
  refreshTokenInput.type = 'hidden'
  refreshTokenInput.name = 'refreshToken'
  refreshTokenInput.value = getSessionRefreshToken() || 'x'
  form.appendChild(refreshTokenInput)

  document.body.appendChild(form)

  form.submit()
  form.remove()
}

const Container = styled.header`
  height: 60px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;
  background-color: ${(props) => props.theme.palette.text.primary};
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
  }
`

const NavLink = styled.span<{ isActive: boolean }>`
  color: ${(props) => props.theme.palette.common.white};
  font-size: 15px;
  outline: none;
  margin-left: 30px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  padding: 2px 0;
  position: relative;
  top: 2px;

  ${(props) =>
    props.isActive &&
    `
      font-weight: 700;
      border-bottom: 3px solid ${props.theme.palette.secondary.main};
  `}
`

export const NavCustomButtonWrapper = styled.div`
  margin-top: -5px;
  margin-left: auto;
`

export const NavCustomButton = styled.a<{ isActive: boolean }>`
  color: ${(props) => props.theme.palette.common.white};
  font-size: 15px;
  outline: none;
  cursor: pointer;
  padding: 0px 12px;
  background: rgba(94, 98, 107, 0.5);
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;

  div {
    display: inline-flex;
    margin-right: 4px;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main + '40'};
  }

  ${(props) =>
    props.isActive &&
    `
      background: ${props.theme.palette.secondary.main} !important;
  `}
`

const NavButton = styled.button<{ isActive?: boolean }>`
  appearance: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.common.white};
  height: 60px;
  font-size: 15px;
  padding: 0 16px;
  outline: none !important;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main + '40'};
  }

  ${(props) =>
    !!props.isActive &&
    `background-color: ${props.theme.palette.secondary.main} !important;`}
`

const LinkContainer = styled.nav`
  padding-top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 29px;
`
