import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { LoadingButton } from '@mapped/rivet/dist/mui/lab'
import { Button, MenuItem, Select } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { Org } from '@mapped/schema-graph-react-apollo'
import axios from 'axios'
import { FunctionComponent, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import {
  setAuthToken,
  setRefreshToken,
  setSessionAuthToken,
  setSessionRefreshToken,
} from '../../auth/auth0'
import { exchangeToken } from '../../auth/mapped'
import { UserContext } from '../../contexts/user'
import { useNotifications } from '../../hooks/useNotifications'
import { Services } from '../../services'
import { logger } from '../../utils/logger'

export const SwitchOrgModal: FunctionComponent<ISwitchOrgModalProps> = ({
  onClose,
  initialOrgId,
}) => {
  const notifications = useNotifications()
  const { organization, authToken } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(
    initialOrgId || organization?.id
  )
  const orgs = useOrgs()

  async function switchOrg() {
    setIsLoading(true)

    const { access_token, refresh_token } = await exchangeToken(
      authToken!,
      selectedOrg!
    )

    if (!access_token) {
      setIsLoading(false)

      return notifications.push({
        type: 'error',
        message: 'Whoops! Something went wrong...',
      })
    }

    setAuthToken(access_token!)
    setRefreshToken(refresh_token!)
    setSessionAuthToken(access_token!)
    setSessionRefreshToken(refresh_token!)

    window.location.reload()
  }

  return (
    <MappedModal onClose={onClose} open={true} style={{ width: 430 }}>
      <Container>
        <Title>Switch Org</Title>

        <Select
          color="primary"
          variant="outlined"
          value={selectedOrg}
          onChange={(e) => setSelectedOrg(e.target.value as any)}
          style={{ width: '100%' }}
        >
          {orgs.map((org: any) => (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
              {org.id === Services.sandbox.org_id && ' (Sandbox)'}
            </MenuItem>
          ))}
        </Select>

        <Buttons>
          <Button variant="outlined" onClick={onClose} disabled={!!isLoading}>
            Nevermind
          </Button>

          <div style={{ padding: '0 12px' }} />

          <LoadingButton
            type="submit"
            loading={!!isLoading}
            disabled={selectedOrg === organization?.id}
            onClick={switchOrg}
          >
            Switch
          </LoadingButton>
        </Buttons>
      </Container>
    </MappedModal>
  )
}

export function useOrgs() {
  const { authToken } = useContext(UserContext)

  const orgsQuery = useQuery(
    'orgs',

    () =>
      axios
        .get('/api/orgs', { headers: { Authorization: authToken } })
        .then((res) => res?.data),

    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSettled: ({ data }: any) =>
        data?.error && logger.error('[orgs query error]', data?.stack),
    }
  )

  const orgs: Org[] = orgsQuery?.data?.orgs || []
  return orgs
}

const Container = styled.div`
  padding: 36px;
  padding-bottom: 28px;
`

const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 48px;
  margin-top: 0;
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;

  button {
    flex: 1;
  }
`

export interface ISwitchOrgModalProps extends MappedModalProps {
  initialOrgId?: string
}
