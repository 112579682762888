import { GlobalStyles } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import Router from 'next/router'
import NProgress from 'nprogress'
import { FunctionComponent, useContext } from 'react'
import { BillingContext } from '../../contexts/billing'
import { UserContext } from '../../contexts/user'
import { Restrict } from '../user/restrict'
import { Header } from './pageHeader'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

export const Layout: FunctionComponent<{
  direction?: 'row' | 'column'
  children: any
}> = ({ children, direction = 'column' }) => {
  const { organization } = useContext(UserContext)
  const { shouldShowUpgradeBanner } = useContext(BillingContext)

  return (
    <>
      <GlobalStyles
        styles={() => {
          return {
            'html, body': {
              scrollBehavior: 'smooth',
            },
          }
        }}
      />

      <Header />

      <div
        id="content"
        style={{
          display: 'flex',
          flexDirection: direction,
          width: '100%',
          height: '100%',
          paddingTop: shouldShowUpgradeBanner ? 120 : 60,
        }}
      >
        {children}
      </div>

      <Restrict obo={true}>
        <ModeBanner style={{ background: '#f2c94c', color: '#000' }}>
          You are viewing this console on behalf of
          <strong style={{ marginLeft: '6px' }}>{organization?.name}</strong>
        </ModeBanner>
      </Restrict>

      <Restrict sandbox={true}>
        <ModeBanner style={{ background: '#51C0AC', color: '#fff' }}>
          <strong style={{ marginLeft: '6px' }}>SANDBOX MODE</strong>
        </ModeBanner>
      </Restrict>
    </>
  )
}

const ModeBanner = styled.div`
  height: 30px;
  width: 100%;
  letter-spacing: 0.5px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  font-size: 15px;
  pointer-events: none;
  opacity: 0.7;
`
