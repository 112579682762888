import { gql, useMutation, useQuery } from '@apollo/client'
import {
  MutationUpdateUserArgs,
  Role,
  User,
} from '@mapped/schema-graph-react-apollo'
import { FunctionComponent, useContext, useState } from 'react'
import { logout } from '../../auth/auth0'
import { UserContext } from '../../contexts/user'
import { USERS_QUERY } from '../../pages/users'
import { logger } from '../../utils/logger'
import { DeleteConfirmationModal } from '../common/deleteConfirmationModal'
import { AdminDeletionMessageModal } from './adminDeletionMessageModal'
import { IUserModalData, IUserModalProps, UserModal } from './userModal'

export const UserProfileModal: FunctionComponent<IUserModalProps> = (props) => {
  const { user, refetchLoggedUser } = useContext(UserContext)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isAdminDeletionMessageVisible, setIsAdminDeletionMessageVisible] =
    useState(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const usersQuery = useQuery<{ users: User[] }>(USERS_QUERY, {
    onError: (err) => logger.error('[users query error]', err),
  })

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    onError: (err) => logger.error('[delete user mutation error]', err),
  })
  const [updateUser] = useMutation<any, MutationUpdateUserArgs>(
    UPDATE_USER_MUTATION,
    {
      onError: (err) => logger.error('[update user mutation error]', err),
    }
  )

  const userId = user?.id ?? ''

  async function onSubmit(data: IUserModalData) {
    setIsUpdating(true)

    await updateUser({
      variables: {
        input: {
          user: {
            id: userId,
            name: data.name,
          },
        },
      },

      refetchQueries: ['usersQuery'],
    })

    await refetchLoggedUser()

    props.onClose()
    setIsUpdating(false)
  }

  function canDeleteAdmin() {
    const adminUsers = usersQuery.data?.users?.filter((u) =>
      u.roles?.includes(Role.Admin)
    )

    return (adminUsers?.length ?? 0) > 1
  }

  async function onDelete() {
    setIsDeleting(true)

    const { errors } = await deleteUser({
      variables: { input: { id: userId } },
    })

    if (errors?.length) {
      return setIsDeleting(false)
    }

    logout()
    window.location.assign('/login?accountDeleted=true')
  }

  return (
    <>
      {!isDeleteConfirmationModalOpen && !isAdminDeletionMessageVisible && (
        <UserModal
          {...props}
          disableDeleteButton={!usersQuery.data?.users}
          isLoading={isUpdating}
          user={user}
          title="My profile"
          isUpdatingItself={true}
          onSubmit={onSubmit}
          onRequestAccountDelete={() => {
            if (!canDeleteAdmin()) {
              return setIsAdminDeletionMessageVisible(true)
            }

            setIsDeleteConfirmationModalOpen(true)
          }}
        />
      )}

      {!!isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          figure="/img/balloons.svg"
          title={
            <>
              We're sorry <br />
              to see you go
            </>
          }
          text="Account deletion is final. There will be no way to restore your account."
          open={true}
          isLoading={isDeleting}
          onDelete={onDelete}
          onClose={() => setIsDeleteConfirmationModalOpen(false)}
        />
      )}

      {!!isAdminDeletionMessageVisible && (
        <AdminDeletionMessageModal
          open={true}
          onClose={() => setIsAdminDeletionMessageVisible(false)}
        />
      )}
    </>
  )
}

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($input: UserDeleteRequestInput!) {
    deleteUser(input: $input) {
      _
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UserUpdateRequestInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`
