import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { LoadingButton } from '@mapped/rivet/dist/mui/lab'
import { Button } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { FunctionComponent, ReactNode } from 'react'

export const DeleteConfirmationModal: FunctionComponent<
  IDeleteConfirmationModalProps
> = ({ isLoading, onDelete, title, text, figure, ...props }) => {
  return (
    <MappedModal {...props} style={{ width: 440 }}>
      <Container>
        {!!figure && <Figure src={figure} />}

        <Title>{title}</Title>

        <Text>{text}</Text>

        <Buttons>
          <Button color="error" onClick={props.onClose} disabled={!!isLoading}>
            Nevermind
          </Button>

          <div style={{ padding: '0 12px' }} />

          <LoadingButton
            color="error"
            variant="outlined"
            type="submit"
            loading={!!isLoading}
            onClick={onDelete}
          >
            Delete
          </LoadingButton>
        </Buttons>
      </Container>
    </MappedModal>
  )
}

const Container = styled.div`
  padding: 36px;
  padding-bottom: 28px;
`

const Figure = styled.img`
  width: 112px;
  height: 199px;
  margin-bottom: 48px;
`

const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 0;
`

const Text = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.palette.text.a50};
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;

  button {
    flex: 1;
  }
`

interface IDeleteConfirmationModalProps extends MappedModalProps {
  title: ReactNode
  text: ReactNode
  figure?: string
  isLoading?: boolean
  onDelete?: () => void
}
