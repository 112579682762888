import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { Button } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { FunctionComponent } from 'react'

export const AdminDeletionMessageModal: FunctionComponent<MappedModalProps> = (
  props
) => {
  return (
    <MappedModal {...props} style={{ width: 440 }}>
      <Container>
        <Title>Hold up...</Title>

        <Text>
          You are currently the only Admin for your organization. <br />
          <br />
          Please assign a new Admin from the Users list before deleting your
          account.
        </Text>

        <Buttons>
          <div style={{ flex: 1 }} />

          <Button onClick={props.onClose}>Ok</Button>
        </Buttons>
      </Container>
    </MappedModal>
  )
}

const Container = styled.div`
  padding: 36px;
  padding-bottom: 28px;
`

const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 0;
`

const Text = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.palette.text.a50};
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;

  button {
    flex: 1;
  }
`
