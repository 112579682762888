import { gql, useQuery } from '@apollo/client'
import { AdminBadge } from '@mapped/rivet/dist/mapped/icons'
import { ResponsiveContainer } from '@mapped/rivet/dist/mapped/responsive/container'
import { classnames } from '@mapped/rivet/dist/mapped/utils/classnames'
import { DataGridPro } from '@mapped/rivet/dist/mui/data-grid'
import { Edit, Search } from '@mapped/rivet/dist/mui/icons'
import {
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import {
  Invite,
  InviteStatus,
  Role,
  User,
} from '@mapped/schema-graph-react-apollo'
import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import Head from 'next/head'
import { FunctionComponent, useContext, useState } from 'react'
import withAuth from '../auth/withAuth'
import { UpgradeModal } from '../components/billing/upgradeModal'
import { Layout } from '../components/layout'
import { PageTitle } from '../components/layout/pageTitle'
import { UserEditModal } from '../components/user/editModal'
import { UserInviteModal } from '../components/user/inviteModal'
import { Restrict } from '../components/user/restrict'
import { BillingContext, EPlanSlug } from '../contexts/billing'
import { UserContext } from '../contexts/user'
import { useModals } from '../hooks/useModals'
import { isCypress } from '../utils/cypress'
import { logger } from '../utils/logger'

const UsersPage: FunctionComponent = () => {
  const modals = useModals()
  const { user: loggedUser } = useContext(UserContext)
  const { subscriptionPlan } = useContext(BillingContext)

  const [searchTerm, setSearchTerm] = useState<string>('')

  const usersQuery = useQuery<IUsersQueryPayload>(USERS_QUERY, {
    onError: (err) => logger.error('[users query error]', err),
  })

  const invitesQuery = useQuery<IInvitesQueryPayload>(INVITES_QUERY, {
    onError: (err) => logger.error('[invites query error]', err),
  })

  const loading = usersQuery.loading || invitesQuery.loading
  const isStarterPlan = subscriptionPlan === EPlanSlug.STARTER

  function getFilteredUsers() {
    const st = searchTerm?.toLowerCase()
    const users = usersQuery.data?.users || []
    const invites = invitesQuery?.data?.invites || []
    const data = [...users, ...invites] as IUserOrInvite[]

    return (
      data.filter((usr) => {
        if (
          usr.id.startsWith('INVITE') &&
          usr.status !== InviteStatus.Pending
        ) {
          return false
        }

        return (
          usr.email?.toLowerCase().includes(st) ||
          usr.name?.toLowerCase().includes(st) ||
          usr.roles?.map((r) => r.toLowerCase()).includes(st)
        )
      }) || []
    )
  }

  return (
    <Restrict roles={[Role.Admin]} sandbox={false} shouldRedirect={true}>
      <Head>
        <title>Mapped Console • Users</title>
      </Head>

      <Layout>
        <Container data-testid="users-table">
          <Header>
            <PageTitle title="Users" style={{ marginBottom: 0 }} />

            <Button
              style={{ whiteSpace: 'nowrap' }}
              onClick={() =>
                modals.open(
                  isStarterPlan && !isCypress() ? UpgradeModal : UserInviteModal
                )
              }
            >
              Invite teammate
            </Button>
          </Header>

          <SearchContainer>
            <TextField
              placeholder="Search by email, name or role"
              value={searchTerm}
              fullWidth={true}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </SearchContainer>

          <DataGridPro
            components={{ LoadingOverlay: () => <LinearProgress /> }}
            loading={loading}
            autoHeight={true}
            disableColumnMenu={true}
            hideFooter={true}
            disableSelectionOnClick={true}
            className={classnames({ borderless: true, loading })}
            density="comfortable"
            sx={{
              '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
                padding: '0 20px',
              },
            }}
            columns={[
              {
                field: 'email',
                flex: 1,
                renderCell: ({ row }) => {
                  const isInvite = row.user.status

                  return (
                    <div>
                      <p style={{ color: isInvite ? '#F2C94C' : undefined }}>
                        {isInvite ? 'Pending...' : row.user.name}
                      </p>

                      <UserEmail>{row.user.email}</UserEmail>
                    </div>
                  )
                },
              },
              {
                field: 'role',
                flex: 1,
                renderCell: ({ row }) => {
                  const role = row.user.roles?.includes(Role.Admin)
                    ? Role.Admin
                    : Role.Explorer

                  return (
                    <UserRole>
                      <span>{capitalize(role)}</span>

                      {role === Role.Admin && (
                        <AdminBadge
                          color="secondary"
                          style={{
                            marginLeft: '10px',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      )}
                    </UserRole>
                  )
                },
              },
              {
                field: 'created',
                flex: 1,
                headerName: 'Date Added',
                renderCell: ({ row }) =>
                  DateTime.fromJSDate(
                    new Date(row.user.created)
                  ).toLocaleString(DateTime.DATETIME_MED),
              },
              {
                field: 'edit',
                flex: 1,
                headerName: '',
                sortable: false,
                headerClassName: 'empty',
                align: 'right',
                renderCell: ({ row }) => {
                  const isViewer = row.id === loggedUser?.id

                  return isViewer ? null : (
                    <Edit
                      sx={{
                        width: '20px',
                        cursor: 'pointer',
                        ':hover': { opacity: 0.5 },
                      }}
                      color="primary"
                      onClick={() =>
                        modals.open(UserEditModal, {
                          user: row.user,
                          isInvite: !!row.user.status,
                        })
                      }
                    />
                  )
                },
              },
            ]}
            rows={getFilteredUsers()?.map((user) => {
              return {
                id: user.id,
                user,
                email: user.email,
                role: user.roles?.includes(Role.Admin)
                  ? Role.Admin
                  : Role.Explorer,
                created: new Date(user.created).getTime(),
              }
            })}
          />
        </Container>
      </Layout>
    </Restrict>
  )
}

export const USERS_QUERY = gql`
  query usersQuery {
    users {
      id
      name
      email
      roles
      created
    }
  }
`

const INVITES_QUERY = gql`
  query invitesQuery {
    invites {
      id
      email
      roles
      status
      created
    }
  }
`

const Container = styled(ResponsiveContainer)`
  padding: 80px 0;

  table td:last-of-type {
    width: 70px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
`

const SearchContainer = styled.div`
  width: 450px;
  margin-bottom: 24px;
`

const UserEmail = styled.p`
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => props.theme.palette.text.a50};
`

const UserRole = styled.div`
  display: flex;
  align-items: center;
`

interface IUsersQueryPayload {
  users: User[]
}

interface IInvitesQueryPayload {
  invites: Invite[]
}

interface IUserOrInvite {
  id: string
  email: string
  roles: Role[]
  created: string
  name?: string
  status?: InviteStatus
}

export default withAuth(UsersPage)
