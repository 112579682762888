import { Close } from '@mapped/rivet/dist/mui/icons'
import { Button, IconButton } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { BillingContext } from '../../contexts/billing'
import { useModals } from '../../hooks/useModals'
import { EStorageKey } from '../../types/general'
import { SubscriptionModal } from './subscriptionModal'

export const UpgradeBanner: FunctionComponent = () => {
  const router = useRouter()
  const { shouldShowUpgradeBanner } = useContext(BillingContext)

  const modals = useModals()

  if (!shouldShowUpgradeBanner) {
    return null
  }

  return (
    <Container>
      <span>You're on Mapped's Starter plan</span>

      <Button
        variant="outlined"
        color="inherit"
        size="medium"
        onClick={() => modals.open(SubscriptionModal)}
      >
        Upgrade Now
      </Button>

      <IconButton
        style={{ position: 'absolute', right: '15px' }}
        size="small"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => {
          sessionStorage.setItem(EStorageKey.SHOULD_HIDE_UPGRADE_BANNER, 'true')
          router.replace(location.href, undefined, { shallow: true })
        }}
      >
        <Close style={{ color: 'white' }} />
      </IconButton>
    </Container>
  )
}

const Container = styled.div`
  background: ${(props) => props.theme.palette.primary.main};
  color: white;
  height: 60px;
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  > span {
    margin-right: 40px;
  }
`
