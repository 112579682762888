import { mediaScreen } from '@mapped/rivet/dist/mapped/utils/mediaScreen'
import { StarBorder } from '@mapped/rivet/dist/mui/icons'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { Role } from '@mapped/schema-graph-react-apollo'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { logout } from '../../../../auth/auth0'
import {
  BillingContext,
  EPlanSlug,
  ProductDetails,
} from '../../../../contexts/billing'
import { UserContext } from '../../../../contexts/user'
import { useModals } from '../../../../hooks/useModals'
import { Services } from '../../../../services'
import { SubscriptionModal } from '../../../billing/subscriptionModal'
import { UserProfileModal } from '../../../user/profileModal'
import { Restrict } from '../../../user/restrict'
import { SwitchOrgModal, useOrgs } from '../../../user/switchOrgModal'
import { TheVoid } from '../../pageHeader'

export const UserMenu: FunctionComponent<{
  onClose?: () => void
}> = ({ onClose }) => {
  const { push } = useRouter()
  const modals = useModals()
  const { user, isOBO } = useContext(UserContext)
  const orgs = useOrgs()

  return (
    <>
      <TheVoid onClick={onClose} />

      <UserMenuContainer>
        <UserMenuInner data-testid="user-dropdown">
          <div className="org">
            <span>{user?.name}</span>

            {orgs.length > 1 && !isOBO && (
              <b
                onClick={() => {
                  onClose?.()
                  modals.open(SwitchOrgModal)
                }}
              >
                Switch Org
              </b>
            )}
          </div>

          <SubscriptionPlanBadge onClose={onClose} />

          <UserMenuLink href="#" onClick={() => modals.open(UserProfileModal)}>
            My profile
          </UserMenuLink>

          <Restrict roles={[Role.Admin]} sandbox={false}>
            <UserMenuLink onClick={() => push('/users')}>Users</UserMenuLink>

            <UserMenuLink onClick={() => push('/settings')}>
              Settings
            </UserMenuLink>
          </Restrict>

          <UserMenuLink
            href="#"
            onClick={(e) => {
              e.preventDefault()
              logout()

              setTimeout(() => (window.location.href = '/login'), 300)
            }}
          >
            Sign out
          </UserMenuLink>

          {!!Services.app.version && <Version>{Services.app.version}</Version>}
        </UserMenuInner>
      </UserMenuContainer>
    </>
  )
}

const SubscriptionPlanBadge: FunctionComponent<any> = ({ onClose }) => {
  const modals = useModals()
  const { subscriptionPlan } = useContext(BillingContext)

  return (
    <PlanBadgeContainer>
      <Restrict
        plans={[EPlanSlug.PRO]}
        fallback={
          <>
            <div className="plan-name">
              {ProductDetails[subscriptionPlan]?.title}
            </div>

            <Restrict roles={[Role.Admin]} sandbox={false}>
              <div
                className="upgrade-button"
                onClick={() => onClose?.() && modals.open(SubscriptionModal)}
              >
                <StarBorder style={{ width: 20, height: 20 }} color="inherit" />
                Upgrade Account
              </div>
            </Restrict>
          </>
        }
      >
        <img src="/img/pro-member-badge.svg" />
      </Restrict>
    </PlanBadgeContainer>
  )
}

const UserMenuContainer = styled.div`
  ${mediaScreen('lg')} {
    z-index: 20;
    position: absolute;
    padding-top: 0;
    right: 0;
    min-width: 237px;
    border: 1px solid ${(props) => props.theme.palette.divider};
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding-left: 0;
  }
  padding-top: 2em;
  padding-left: 2em;
`

const UserMenuInner = styled.div`
  ${mediaScreen('lg')} {
    background: ${(props) => props.theme.palette.common.white};
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    font-weight: 500;
  }

  padding: 8px 0 4px;

  span {
    display: flex;
    flex-direction: row;

    ${mediaScreen('lg')} {
      flex-direction: column;
    }
  }

  .org {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;

    span {
      font-size: 14px;
      color: ${(props) => props.theme.palette.text.primary};
      flex: 1;
      margin-right: 30px;
    }

    b {
      color: ${(props) => props.theme.palette.primary.main};
      font-size: 11px;
      cursor: pointer;
      position: relative;
      top: 1px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const UserMenuLink = styled.a`
  ${mediaScreen('lg')} {
    font-size: 14px;
    color: ${(props) => props.theme.palette.primary.main};
    padding: 6px 16px;
  }

  color: ${(props) => props.theme.palette.common.white};
  font-size: 1.4em;
  display: block;
  padding: 10px 16px;
  cursor: pointer;
  outline: none !important;

  &:hover {
    ${mediaScreen('lg')} {
      background-color: ${(props) => props.theme.palette.text.a03};
    }
  }
`

const Version = styled.div`
  display: none;
  font-size: 11px;
  margin: 0;
  color: ${(props) => props.theme.palette.text.a50};
  font-weight: 100;
  opacity: 0.3;
  padding: 20px 16px 10px 16px;
  word-wrap: break-word;

  ${mediaScreen('lg')} {
    display: block;
  }
`

const PlanBadgeContainer = styled.div`
  width: calc(100% - 30px);
  display: flex;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-bottom: 15px;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};

  img {
    width: 100%;
  }

  .plan-name {
    text-transform: uppercase;
    color: white;
    background: ${(props) => props.theme.palette.secondary.main};
    font-size: 11px;
    font-weight: bold;
    padding: 7px 12px;
  }

  .upgrade-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${(props) => props.theme.palette.text.a50};

    .MuiSvgIcon-root {
      margin-right: 5px;
    }

    &:hover {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`
