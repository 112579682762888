import type {
  AddOrgUserAsyncResponse,
  CheckOnboardOrgStatusResponse,
  CheckOrgUserStatusResponse,
  CheckVerifyAuth0UserAsyncResponse,
  OnboardNewOrgAsyncResponse,
  VerifyAuth0UserAsyncResponse,
} from '@mapped/proto-node-nice/mapped/cloud/api/orgs/orgs'
import axios from 'axios'
import jwt from 'jwt-simple'
import { INextAPIError } from '../types/general'
import { logger } from '../utils/logger'

export async function verifyUser({ accessToken, isNewSignup }: any) {
  const res = await axios.post<VerifyAuth0UserAsyncResponse & INextAPIError>(
    '/api/signup-async/verify',
    {
      accessToken,
      isNewSignup,
    }
  )

  if (res?.data?.error) {
    logger.error(
      '[signup-async verify error]',
      logContext(res?.data?.stack, accessToken)
    )
  }

  const check = () => {
    return new Promise<{
      userStatus: 'error' | 'relogin' | 'OK'
      extendedStatus?: CheckVerifyAuth0UserAsyncResponse['extendedStatus']
    }>((resolve) => {
      axios
        .post<CheckVerifyAuth0UserAsyncResponse & INextAPIError>(
          '/api/signup-async/check-verify',
          { accessToken, checkToken: res.data?.checkToken }
        )
        .then(({ data }) => {
          if (data.error) {
            logger.error(
              '[signup-async check-verify error]',
              logContext(data?.stack, accessToken)
            )

            return resolve({ userStatus: 'error' })
          }

          const status = data?.userStatus?.status! as any

          if (['relogin', 'OK'].includes(status)) {
            return resolve({
              userStatus: status,
              extendedStatus: data?.extendedStatus,
            })
          }

          setTimeout(() => resolve(check()), 2000)
        })
    })
  }

  return await check()
}

export async function acceptInvite({ accessToken, inviteToken }: any) {
  const res = await axios.post<AddOrgUserAsyncResponse & INextAPIError>(
    '/api/signup-async/accept-invite',
    {
      accessToken,
      inviteToken,
    }
  )

  if (res?.data?.error) {
    logger.error(
      '[signup-async accept-invite error]',
      logContext(res?.data?.stack, accessToken)
    )
  }

  const check = () => {
    return new Promise<boolean>((resolve) => {
      axios
        .post<CheckOrgUserStatusResponse & INextAPIError>(
          '/api/signup-async/check-accept-invite',
          { accessToken, checkToken: res.data?.checkToken }
        )
        .then(({ data }) => {
          if (data.error) {
            logger.error(
              '[signup-async check-accept-invite error]',
              logContext(data?.stack, accessToken)
            )

            return resolve(false)
          }

          if (data.message === 'OK') {
            return setTimeout(() => resolve(true), 5000)
          }

          setTimeout(() => resolve(check()), 2000)
        })
    })
  }

  return await check()
}

export async function createNewOrg({
  accessToken,
  orgName,
  subscription,
}: any): Promise<undefined | string> {
  const res = await axios.post<OnboardNewOrgAsyncResponse & INextAPIError>(
    '/api/signup-async/add-org',
    {
      accessToken,
      orgName,
      subscription,
    }
  )

  if (res?.data?.error) {
    logger.error(
      '[signup-async add-org error]',
      logContext(res?.data?.stack, accessToken)
    )
  }

  const check = () => {
    return new Promise<undefined | string>((resolve) => {
      axios
        .post<CheckOnboardOrgStatusResponse & INextAPIError>(
          '/api/signup-async/check-org',
          { accessToken, checkToken: res.data.status?.checkToken }
        )
        .then(({ data }) => {
          if (data.error) {
            logger.error(
              '[signup-async check-org error]',
              logContext(data?.stack, accessToken)
            )

            return resolve(undefined)
          }

          if (data.status?.state === 'ACTIVE') {
            return resolve(data?.status?.orgId)
          }

          setTimeout(() => resolve(check()), 2000)
        })
    })
  }

  return await check()
}

export async function exchangeToken(subject_token: string, org_id?: string) {
  const res = await axios.post('/api/exchangeToken', {
    subject_token,
    org_id,
  })

  if (res?.data?.error) {
    logger.info('[exchangeToken fail]', res?.data?.stack)
  }

  return res.data as {
    error?: string
    access_token?: string
    refresh_token?: string
    expires_in?: number
  }
}

function logContext(stack: any, accessToken?: string) {
  const getDecodedAccessToken = () => {
    try {
      return {
        decodedAccessToken: jwt.decode(accessToken!, '', true),
      }
    } catch (e) {
      return {}
    }
  }

  return {
    ...stack,
    ...getDecodedAccessToken(),
  }
}
