import styled from '@emotion/styled'
import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { Close } from '@mapped/rivet/dist/mui/icons'
import { Button, IconButton } from '@mapped/rivet/dist/mui/material'
import { FunctionComponent } from 'react'
import { useModals } from '../../hooks/useModals'
import { SubscriptionModal } from './subscriptionModal'

export const UpgradeModal: FunctionComponent<MappedModalProps> = ({
  onClose,
}) => {
  const modals = useModals()

  return (
    <MappedModal onClose={onClose} open={true} style={{ width: 460 }}>
      <Container>
        <img
          src="/img/collaborate.svg"
          style={{ marginBottom: 40, width: 132 }}
        />

        <Title>Upgrade to collaborate with your team</Title>
        <Description>
          You’re currently on the Starter plan, but our Basic and Pro plans
          allow for additional users - and a whole lot more!
        </Description>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClose}>
            Not now
          </Button>

          <Button onClick={() => modals.open(SubscriptionModal)}>
            View options
          </Button>
        </div>
      </Container>

      <IconButton
        style={{ position: 'absolute', top: '15px', right: '15px' }}
        size="large"
        onClick={onClose}
        disableRipple={true}
      >
        <Close />
      </IconButton>
    </MappedModal>
  )
}

const Container = styled.div`
  padding: 40px;
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 25px;
`

const Description = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 50px;
`
