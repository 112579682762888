import { FunctionComponent } from 'react'

import { useFeatureFlag } from '../../hooks/useFeatureFlag'
import { EFeature } from '../../types/general'

export const Feature: FunctionComponent<{ name: EFeature }> = ({
  name,
  children,
}) => {
  const [canUseFeature] = useFeatureFlag([name])

  if (!canUseFeature) {
    return <></>
  }

  return <>{children}</>
}
