import { gql, useApolloClient } from '@apollo/client'
import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { TagsInput } from '@mapped/rivet/dist/mapped/tags-input'
import { LoadingButton } from '@mapped/rivet/dist/mui/lab'
import { Button, MenuItem, Select } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import {
  MutationCreateInviteArgs,
  Role,
} from '@mapped/schema-graph-react-apollo'
import * as EmailValidator from 'email-validator'
import { FunctionComponent, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isCypress } from '../../utils/cypress'
import { logger } from '../../utils/logger'

export const UserInviteModal: FunctionComponent<IUserInviteModalProps> = ({
  onClose,
}) => {
  const apolloClient = useApolloClient()

  const [step, setStep] = useState<EStep>(EStep.EMAILS)
  const [isLoading, setIsLoading] = useState(false)
  const [successfulInvitesCount, setSuccessfulInvitesCount] = useState(0)
  const [emails, setEmails] = useState<string[]>([])
  const [roles, setRoles] = useState<Role[]>([])

  async function inviteUser(email: string, role: Role) {
    try {
      const { errors } = await apolloClient.mutate<
        any,
        MutationCreateInviteArgs
      >({
        mutation: CREATE_INVITE_MUTATION,
        variables: {
          input: {
            skipSendEmail: isCypress(),
            invite: {
              email,
              roles: [role as any],
            },
          },
        },
      })

      return !errors?.length
    } catch (e: any) {
      logger.error(`[create invite mutation error]`, {
        error: e,
        email,
        role,
      })

      return false
    }
  }

  async function inviteAllUsers() {
    const promises = emails.map((email, idx) => {
      const role = roles[idx]

      return inviteUser(email, role)
    })

    const results = await Promise.all(promises)

    return results.filter((sent) => sent).length
  }

  function onBack() {
    if (step === EStep.EMAILS) {
      onClose()
    }

    if (step === EStep.ROLES) {
      setStep(EStep.EMAILS)
    }
  }

  async function onNext() {
    if (step === EStep.EMAILS) {
      const initialRoles = generateRolesArray(Role.Explorer, emails.length)

      setRoles(initialRoles)
      setStep(EStep.ROLES)
      return
    }

    if (step === EStep.ROLES) {
      setIsLoading(true)

      const successfulInvites = await inviteAllUsers()

      await apolloClient.reFetchObservableQueries()

      setIsLoading(false)
      setSuccessfulInvitesCount(successfulInvites)
      setStep(EStep.SUCCESS)
    }
  }

  if (step === EStep.SUCCESS) {
    return (
      <SuccessMessage
        onDismiss={onClose}
        successfulInvitesCount={successfulInvitesCount}
      />
    )
  }

  return (
    <MappedModal onClose={onClose} open={true} style={{ width: 650 }}>
      <Container>
        <Title>
          {step === EStep.EMAILS ? 'Invite your teammates' : 'Assign roles'}
        </Title>

        <Text>
          {step === EStep.EMAILS
            ? 'Add teammates by email address'
            : 'Assign a role to each teammate'}
        </Text>

        {step === EStep.EMAILS && (
          <TagsInput
            value={emails}
            placeholder="Type or paste one or more emails"
            onChange={setEmails}
            validate={EmailValidator.validate}
            disabled={isLoading}
            shouldUseSpaceAsDelimiter={true}
          />
        )}

        {step === EStep.ROLES && (
          <RolesAssignment
            emails={emails}
            roles={roles}
            onChangeRoles={setRoles}
            disabled={isLoading}
          />
        )}

        <Buttons>
          <Button variant="outlined" onClick={onBack} disabled={!!isLoading}>
            {step === EStep.EMAILS ? 'Cancel' : 'Back'}
          </Button>

          <div style={{ padding: '0 12px' }} />

          <LoadingButton
            loading={!!isLoading}
            disabled={step === EStep.EMAILS ? !emails.length : false}
            onClick={onNext}
          >
            {step === EStep.EMAILS ? 'Next' : 'Send Invites'}
          </LoadingButton>
        </Buttons>
      </Container>
    </MappedModal>
  )
}

const RolesAssignment: FunctionComponent<IRolesAssignment> = ({
  emails,
  roles,
  onChangeRoles,
  disabled,
}) => {
  function changeRole(index: number, role: Role) {
    const newRoles = roles.map((_, idx) => {
      if (index === idx) {
        return role
      }

      return _
    })

    onChangeRoles(newRoles)
  }

  return (
    <RolesAssignmentContainer>
      {emails.map((email, idx) => {
        const role = roles[idx]

        return (
          <RolesAssignmentItem key={email}>
            <strong>{email}</strong>

            <Select
              data-testid="role-select"
              sx={{ width: 120 }}
              value={role}
              onChange={(e) => changeRole(idx, e.target.value as Role)}
              disabled={disabled}
            >
              <MenuItem value={Role.Admin}>Admin</MenuItem>
              <MenuItem value={Role.Explorer}>Explorer</MenuItem>
            </Select>
          </RolesAssignmentItem>
        )
      })}
    </RolesAssignmentContainer>
  )
}

const SuccessMessage: FunctionComponent<{
  onDismiss: () => void
  successfulInvitesCount: number
}> = ({ onDismiss }) => {
  return (
    <MappedModal onClose={onDismiss} open={true} style={{ width: 650 }}>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src="/img/invites-sent.svg"
          width="120"
          height="120"
          style={{ marginBottom: 36 }}
        />
        <Title style={{ marginBottom: 36 }}>On the way!</Title>

        <Button style={{ width: 150 }} onClick={onDismiss}>
          Dismiss
        </Button>
      </Container>
    </MappedModal>
  )
}

function generateRolesArray(role: Role, length: number) {
  return Array.from(([] as any).fill.call({ length }, role)) as Role[]
}

const CREATE_INVITE_MUTATION = gql`
  mutation createInvite($input: InviteCreateRequestInput!) {
    createInvite(input: $input) {
      invite {
        id
      }
    }
  }
`

const Container = styled.div`
  padding: 36px;
  padding-bottom: 28px;
`

const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 12px;
  text-align: center;
  margin-top: 0;
`

const Text = styled.p`
  font-size: 20px;
  font-weight: 300px;
  text-align: center;
  color: ${(props) => props.theme.palette.text.a50};
  margin-bottom: 36px;
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;
  justify-content: space-between;

  button {
    width: 150px;
  }
`

const RolesAssignmentContainer = styled(PerfectScrollbar)`
  max-height: 400px;
  width: 100%;
  padding-bottom: 90px;
`

const RolesAssignmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;

  strong {
    display: block;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 24px;
    flex: 1;
  }

  .dropdown {
    width: 180px;
  }
`

export interface IUserInviteModalProps extends MappedModalProps {}

enum EStep {
  EMAILS,
  ROLES,
  SUCCESS,
}

interface IRolesAssignment {
  emails: string[]
  roles: Role[]
  onChangeRoles: (roles: Role[]) => void
  disabled?: boolean
}
