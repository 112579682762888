import { styled } from '@mapped/rivet/dist/mui/styles'
import { CSSProperties, FunctionComponent, ReactNode } from 'react'

export const PageTitle: FunctionComponent<IPageTitle> = ({
  id,
  title,
  style,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Container
      id={id}
      style={style}
      className="page-title"
      data-testid="page-title"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!!title && <h1>{title}</h1>}

      {children}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  &:empty {
    opacity: 0;
  }

  &:not(:empty) {
    opacity: 1;
  }

  h1 {
    flex: 1;
    min-height: 72px;
    font-size: 48px;
    margin: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

interface IPageTitle {
  id?: string
  title?: ReactNode
  style?: CSSProperties
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}
