import { Box, GlobalStyles } from '@mapped/rivet/dist/mui/material'
import type { SxProps, Theme } from '@mapped/rivet/dist/mui/styles'
import { Role } from '@mapped/schema-graph-react-apollo'
import { FunctionComponent, useContext } from 'react'
import { BillingContext, EPlanSlug } from '../../contexts/billing'
import { UserContext } from '../../contexts/user'

export const Restrict: FunctionComponent<
  IRestrictions & {
    shouldRedirect?: boolean
    fallback?: any
    children?: any
  }
> = ({ shouldRedirect, children, fallback, ...restrictions }) => {
  const isEverythingOK = checkRestrictions(restrictions)

  if (isEverythingOK) {
    return children
  }

  if (shouldRedirect) {
    window.location.href = '/explore'
    return null
  }

  return fallback || null
}

export const RestrictStyles: FunctionComponent<
  IRestrictions & {
    styles?: SxProps<Theme>
    fallbackStyles?: SxProps<Theme>
    global?: boolean
  }
> = ({ children, styles, fallbackStyles, global, ...restrictions }) => {
  const isEverythingOK = checkRestrictions(restrictions)

  if (global) {
    return (
      <>
        <GlobalStyles
          styles={(isEverythingOK ? styles : fallbackStyles) as any}
        />
        {children}
      </>
    )
  }

  return <Box sx={isEverythingOK ? styles : fallbackStyles}>{children}</Box>
}

function checkRestrictions({ roles, plans, sandbox, obo, and }: IRestrictions) {
  const { user, isSandbox, isOBO } = useContext(UserContext)
  const { subscriptionPlan } = useContext(BillingContext)

  const isRoleOK = !roles
    ? true
    : user?.roles?.some((role) => roles.includes(role!))

  const isPlanOK = !plans ? true : plans?.includes(subscriptionPlan)

  let isSandboxOK = sandbox === undefined ? true : sandbox === isSandbox

  if (isSandbox && user?.roles?.includes(Role.Admin)) {
    isSandboxOK = true
  }

  const isOBOOK = obo === undefined ? true : obo === isOBO

  const isAndOK =
    and === undefined
      ? true
      : and({
          userRoles: user?.roles as any,
          subscriptionPlan,
          isSandbox: isSandbox!,
          isOBO,
        })

  return isRoleOK && isPlanOK && isSandboxOK && isOBOOK && isAndOK
}

interface IRestrictions {
  roles?: Role[]
  plans?: EPlanSlug[]
  sandbox?: boolean
  obo?: boolean
  and?: (props: {
    userRoles: Role[]
    subscriptionPlan: EPlanSlug
    isSandbox: boolean
    isOBO: boolean
  }) => boolean
}
