import {
  ToggleOffOutlined,
  ToggleOnOutlined,
} from '@mapped/rivet/dist/mui/icons'
import { GlobalStyles, LinearProgress } from '@mapped/rivet/dist/mui/material'
import { CSSProperties, FunctionComponent, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { getSessionAuthToken } from '../../auth/auth0'
import { UserContext } from '../../contexts/user'
import { useModals } from '../../hooks/useModals'
import { useNotifications } from '../../hooks/useNotifications'
import { Services } from '../../services'
import { NavCustomButton, NavCustomButtonWrapper } from '../layout/pageHeader'
import { SwitchOrgModal, useOrgs } from '../user/switchOrgModal'

export const SandboxToggle: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const notifications = useNotifications()
  const { isOBO, organization } = useContext(UserContext)
  const modals = useModals()
  const orgs = useOrgs()
  const [isLoading, setIsLoading] = useState(false)

  const isSandbox = Services.sandbox.org_id === organization?.id

  async function onToggle() {
    if (isOBO) {
      return notifications.push({
        type: 'error',
        message: 'You cannot toggle sandbox in OBO mode.',
      })
    }

    const hasSandboxOrg =
      isSandbox || orgs.some((org) => org.id === Services.sandbox.org_id)

    if (hasSandboxOrg) {
      const homeOrgs = orgs.filter((org) => org.id !== Services.sandbox.org_id)
      const homeOrgId = homeOrgs.length === 1 ? homeOrgs[0]?.id : undefined

      return modals.open(SwitchOrgModal, {
        initialOrgId: isSandbox ? homeOrgId! : Services.sandbox.org_id,
      })
    }

    setIsLoading(true)

    const res = await fetch(
      `/api/join-sandbox-org?currentOrg=${organization?.id}`,
      {
        method: 'POST',
        cache: 'no-store',
        next: { revalidate: 1 },
        headers: {
          authorization: getSessionAuthToken()!,
        },
      }
    )

    const data = await res.json()

    if (!data?.ok) {
      onToggle()
      return
    }

    await queryClient.refetchQueries(['orgs'])
    modals.open(SwitchOrgModal, { initialOrgId: Services.sandbox.org_id })
    setIsLoading(false)
  }

  const ToggleIcon = isSandbox ? ToggleOnOutlined : ToggleOffOutlined

  return (
    <NavCustomButtonWrapper id="sandbox-toggle">
      <NavCustomButton
        isActive={!!isSandbox}
        href="#"
        style={{ margin: 0 }}
        onClick={isLoading ? undefined : onToggle}
      >
        {isLoading ? (
          <>
            <LinearProgress
              sx={{
                width: '146.5px',
                top: 0.5,
                backgroundColor: '#ffffff40',
                span: { backgroundColor: '#ffffff99' },
              }}
            />

            <GlobalStyles
              styles={{
                '*': {
                  pointerEvents: 'none !important' as any,
                  userSelect: 'none !important' as any,
                },
              }}
            />
          </>
        ) : (
          <>
            <ToggleIcon style={iconStyle} />
            <span>Sandbox Mode</span>
          </>
        )}
      </NavCustomButton>
    </NavCustomButtonWrapper>
  )
}

const iconStyle = {
  marginRight: 12,
  width: 30,
  height: 30,
  position: 'relative',
  top: 0.3,
} as CSSProperties
