import { gql, useMutation } from '@apollo/client'
import {
  InviteStatusInput,
  MutationAssignRoleArgs,
  MutationDeleteInviteArgs,
  MutationRemoveOrgMembershipUserArgs,
  MutationRemoveRoleArgs,
  MutationUpdateInviteArgs,
  Role,
} from '@mapped/schema-graph-react-apollo'
import { FunctionComponent, useContext, useState } from 'react'
import { UserContext } from '../../contexts/user'
import { logger } from '../../utils/logger'
import { DeleteConfirmationModal } from '../common/deleteConfirmationModal'
import { IUserModalData, IUserModalProps, UserModal } from './userModal'

export const UserEditModal: FunctionComponent<IUserModalProps> = (props) => {
  const { organization } = useContext(UserContext)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const [updateInvite] = useMutation<any, MutationUpdateInviteArgs>(
    UPDATE_INVITE_MUTATION,
    {
      onError: (err) => logger.error('[update invite mutation error]', err),
    }
  )

  const [deleteInvite] = useMutation<any, MutationDeleteInviteArgs>(
    DELETE_INVITE_MUTATION,
    {
      onError: (err) => logger.error('[delete invite mutation error]', err),
    }
  )

  const [deleteUser] = useMutation<any, MutationRemoveOrgMembershipUserArgs>(
    DELETE_USER_MUTATION,
    {
      onError: (err) => logger.error('[delete user mutation error]', err),
    }
  )

  const [assignRole] = useMutation<any, MutationAssignRoleArgs>(
    ASSIGN_ROLE_MUTATION,
    {
      onError: (err) => logger.error('[assign role mutation error]', err),
    }
  )

  const [removeRole] = useMutation<any, MutationRemoveRoleArgs>(
    REMOVE_ROLE_MUTATION,
    {
      onError: (err) => logger.error('[remove role mutation error]', err),
    }
  )

  const entityId = props.user?.id ?? ''
  const entityName = props.isInvite ? 'invite' : 'user'

  async function onSubmit(data: IUserModalData) {
    setIsUpdating(true)

    if (props.isInvite) {
      await updateInvite({
        variables: {
          input: {
            invite: {
              id: entityId,
              roles: [data.role as any],
              status: InviteStatusInput.Pending,
            },
          },
        },
        refetchQueries: ['invitesQuery'],
        awaitRefetchQueries: true,
      })
    } else {
      const roleToAssign = data.role as Role
      const roleToRemove =
        roleToAssign === Role.Explorer ? Role.Admin : Role.Explorer

      await assignRole({
        variables: { input: { userId: entityId, role: roleToAssign as any } },
      })

      await removeRole({
        variables: { input: { userId: entityId, role: roleToRemove as any } },
        refetchQueries: ['usersQuery'],
        awaitRefetchQueries: true,
      })
    }

    props.onClose?.()
    setIsUpdating(false)
  }

  async function onDelete() {
    setIsDeleting(true)

    if (props.isInvite) {
      await deleteInvite({
        variables: { input: { id: entityId } },
        refetchQueries: ['invitesQuery'],
        awaitRefetchQueries: true,
      })
    } else {
      await deleteUser({
        variables: { input: { id: entityId, orgId: organization?.id } },
        refetchQueries: ['usersQuery'],
        awaitRefetchQueries: true,
      })
    }

    props.onClose?.()
    setIsDeleting(false)
    setIsDeleteConfirmationModalOpen(false)
  }

  return (
    <>
      {!isDeleteConfirmationModalOpen && (
        <UserModal
          {...props}
          isLoading={isUpdating}
          title={`Edit ${entityName}`}
          onSubmit={onSubmit}
          onRequestAccountDelete={() => setIsDeleteConfirmationModalOpen(true)}
        />
      )}

      {!!isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          title={`Are you sure you want to delete this ${entityName}?`}
          text="This deletion is final. There will be no way to restore it."
          open={true}
          isLoading={isDeleting}
          onDelete={onDelete}
          onClose={() => setIsDeleteConfirmationModalOpen(false)}
        />
      )}
    </>
  )
}

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($input: UserRemoveOrgMembershipRequestInput!) {
    removeOrgMembershipUser(input: $input) {
      _
    }
  }
`

const DELETE_INVITE_MUTATION = gql`
  mutation deleteInvite($input: InviteDeleteRequestInput!) {
    deleteInvite(input: $input) {
      _
    }
  }
`

const UPDATE_INVITE_MUTATION = gql`
  mutation updateInvite($input: InviteUpdateRequestInput!) {
    updateInvite(input: $input) {
      invite {
        id
      }
    }
  }
`

const ASSIGN_ROLE_MUTATION = gql`
  mutation assignRole($input: UserAssignRoleRequestInput!) {
    assignRole(input: $input) {
      user {
        id
      }
    }
  }
`

const REMOVE_ROLE_MUTATION = gql`
  mutation removeRole($input: UserRemoveRoleRequestInput!) {
    removeRole(input: $input) {
      user {
        id
      }
    }
  }
`
