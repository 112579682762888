import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { LoadingButton } from '@mapped/rivet/dist/mui/lab'
import {
  Button,
  MenuItem,
  Select,
  TextField,
} from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { Role, User } from '@mapped/schema-graph-react-apollo'
import { FormEvent, FunctionComponent, useState } from 'react'

export const UserModal: FunctionComponent<IUserModalProps> = ({
  user,
  title,
  isLoading,
  isUpdatingItself,
  disableDeleteButton,
  onClose,
  onSubmit,
  onRequestAccountDelete,
}) => {
  const [name, setName] = useState(user?.name ?? '')

  const [role, setRole] = useState(
    !user?.roles?.length
      ? ''
      : user?.roles?.includes(Role.Admin)
      ? Role.Admin
      : Role.Explorer ?? ''
  )

  function onFormSubmit(e: FormEvent) {
    e.preventDefault()

    onSubmit &&
      onSubmit({
        name,
        role,
      })
  }

  return (
    <MappedModal onClose={onClose} open={true}>
      <Container>
        <Title>{title}</Title>

        <Form onSubmit={onFormSubmit}>
          {isUpdatingItself && (
            <>
              <Label>Name</Label>
              <TextField
                name="name"
                fullWidth={true}
                placeholder="First and last name"
                required={true}
                disabled={!!isLoading}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </>
          )}

          {!isUpdatingItself && (
            <>
              <Label>Role</Label>
              <Select
                fullWidth={true}
                value={role}
                onChange={(e) => setRole(e.target.value)}
                disabled={!!isLoading}
              >
                <MenuItem value={Role.Admin}>Admin</MenuItem>
                <MenuItem value={Role.Explorer}>Explorer</MenuItem>
              </Select>
            </>
          )}

          <Label>Email</Label>
          <TextField
            name="email"
            disabled={true}
            value={user?.email ?? ''}
            fullWidth={true}
          />

          <Buttons>
            <Button variant="outlined" onClick={onClose} disabled={!!isLoading}>
              Cancel
            </Button>

            <div style={{ padding: '0 12px' }} />

            <LoadingButton type="submit" loading={!!isLoading}>
              Save
            </LoadingButton>
          </Buttons>

          <Button
            onClick={onRequestAccountDelete}
            color="error"
            variant="outlined"
            disabled={disableDeleteButton}
            style={{ border: 'none', width: '100%', marginTop: 24 }}
          >
            Delete
          </Button>
        </Form>
      </Container>
    </MappedModal>
  )
}

const Container = styled.div`
  width: 450px;
  background: white;
  padding: 36px;
  padding-bottom: 28px;
`

const Form = styled.form``

const Title = styled.h2`
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 48px;
  margin-top: 0;
`

const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  margin-top: 24px;
  font-weight: 500;
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  padding-bottom: 24px;

  button {
    flex: 1;
  }
`

export interface IUserModalData {
  name: string
  role: string
}

export interface IUserModalProps extends MappedModalProps {
  title?: string
  user?: User
  isUpdatingItself?: boolean
  isLoading?: boolean
  isInvite?: boolean
  disableDeleteButton?: boolean
  onSubmit?: (data: IUserModalData) => void
  onRequestAccountDelete?: () => void
}
