import { useClient } from '@splitsoftware/splitio-react'
import { EFeature } from '../types/general'

export function useFeatureFlag(features: EFeature[]) {
  const client = useClient()

  const treatments = client?.getTreatments(features) ?? {}

  return Object.keys(treatments).map((t) => treatments[t] === 'on')
}
